import React, { useContext } from 'react'
import { AppContext } from '../App'

import { toggleImageActionSuccess, toggleVideoActionSuccess } from '.'

const ToggleMedia = () => {
  const { state, dispatch } = useContext(AppContext)

  return (
    <div className='form-control'>
      <label className='label cursor-pointer'>
        <span className='label-text'>Toggle image/video</span>
        <input
          type='checkbox' className='toggle' checked={state.showVideo}
          onChange={() => !state.showVideo ? dispatch(toggleVideoActionSuccess()) : dispatch(toggleImageActionSuccess())}
        />
      </label>
    </div>
  )
}

export { ToggleMedia }
