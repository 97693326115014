import { createProjectSuccessAction, fetchProjectSuccessAction, deleteProjectSuccessAction, cloneProjectSuccessAction } from '.'
import { toggleImageActionSuccess, toggleVideoActionSuccess } from '../toggleMedia'
import { getCsrfToken } from '../utils/csrfUtils'
import { Config } from '../utils/config'

const createNewProject = async (dispatch, throwAsyncError) => {
  try {
    const csrfToken = await getCsrfToken()

    const response = await fetch(`${Config.BASE_URL}/project`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfToken
      },
      credentials: 'include'
    })

    if (!response.ok) {
      const error = new Error(`HTTP error! Status: ${response.status}`)
      error.status = response.status
      throw error
    }

    const result = await response.json()

    dispatch(createProjectSuccessAction(result))
  } catch (error) {
    console.error(error)
    throwAsyncError(error)
  }
}

const fetchProject = async (dispatch, payload, throwAsyncError) => {
  try {
    const response = await fetch(
      `${Config.BASE_URL}/project/${payload.projectId}`,
      {
        method: 'GET',
        credentials: 'include'
      }
    )

    if (!response.ok) {
      const error = new Error(`HTTP error! Status: ${response.status}`)
      error.status = response.status
      throw error
    }

    const result = await response.json()

    result.project.video_id
      ? dispatch(toggleVideoActionSuccess())
      : dispatch(toggleImageActionSuccess())

    dispatch(fetchProjectSuccessAction(result))
  } catch (error) {
    console.error(error)
    throwAsyncError(error)
  }
}

const deleteProject = async (dispatch, payload, throwAsyncError) => {
  try {
    const csrfToken = await getCsrfToken()

    const response = await fetch(
      `${Config.BASE_URL}/project/${payload.projectId}`,
      {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'x-csrf-token': csrfToken
        }
      }
    )

    if (!response.ok) {
      const error = new Error(`HTTP error! Status: ${response.status}`)
      error.status = response.status
      throw error
    }

    const result = await response.json()
    dispatch(deleteProjectSuccessAction(result))
  } catch (error) {
    console.error(error)
    throwAsyncError(error)
  }
}

const cloneProject = async (dispatch, payload, throwAsyncError) => {
  try {
    const csrfToken = await getCsrfToken()

    const response = await fetch(`${Config.BASE_URL}/project/clone`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfToken
      },
      credentials: 'include',
      body: JSON.stringify({ projectId: payload.projectId })
    })

    if (!response.ok) {
      const error = new Error(`HTTP error! Status: ${response.status}`)
      error.status = response.status
      throw error
    }

    const result = await response.json()
    dispatch(cloneProjectSuccessAction(result))
  } catch (error) {
    console.error(error)
    throwAsyncError(error)
  }
}

export { createNewProject, fetchProject, deleteProject, cloneProject }
