import { CREATE_PROJECT_SUCCESS, FETCH_PROJECT_SUCCESS, DELETE_PROJECT_SUCCESS, CLONE_PROJECT_SUCCESS } from '.'

const createProjectSuccessAction = (payload) => {
  return {
    type: CREATE_PROJECT_SUCCESS,
    payload
  }
}

const fetchProjectSuccessAction = (payload) => {
  return {
    type: FETCH_PROJECT_SUCCESS,
    payload
  }
}

const deleteProjectSuccessAction = (payload) => {
  return {
    type: DELETE_PROJECT_SUCCESS,
    payload
  }
}

const cloneProjectSuccessAction = (payload) => {
  return {
    type: CLONE_PROJECT_SUCCESS,
    payload
  }
}

export { createProjectSuccessAction, fetchProjectSuccessAction, deleteProjectSuccessAction, cloneProjectSuccessAction }
