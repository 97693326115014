const CREATE_PROJECT_SUCCESS = 'app/project/CREATE_PROJECT_SUCCESS'
const FETCH_PROJECT_SUCCESS = 'app/project/FETCH_PROJECT_SUCCESS'
const DELETE_PROJECT_SUCCESS = 'app/project/DELETE_PROJECT_SUCCESS'
const CLONE_PROJECT_SUCCESS = 'app/project/CLONE_PROJECT_SUCCESS'

export {
  CREATE_PROJECT_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  CLONE_PROJECT_SUCCESS
}
