import React, { useContext, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { AppContext } from '../App'
import { HistoryListItem, fetchHistory } from '.'
import { startLoading, finishLoading, Loader } from '../loader'
import { useAsyncError } from '../ErrorBoundary/useAsyncError'

const LIMIT = process.env.LIMIT || 15
const INITIAL_OFFSET = process.env.INITIAL_OFFSET || 0

const HistoryList = () => {
  const { state, dispatch } = useContext(AppContext)
  const throwAsyncError = useAsyncError()

  useEffect(() => {
    (() => {
      try {
        dispatch(startLoading())
        fetchHistory(dispatch, LIMIT, INITIAL_OFFSET, throwAsyncError)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(finishLoading())
      }
    })()
  }, [])

  const loadFunc = (pageNumber) => {
    const offset = pageNumber * LIMIT
    fetchHistory(dispatch, LIMIT, offset, throwAsyncError)
  }

  const items = state.history

  const ReturnItem = ({ item }) => {
    return <HistoryListItem key={item.project_id} item={item} />
  }

  return (
    <div className='px-6 px-4 mb-8'>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={loadFunc}
        hasMore={items.length < state.projectsCount}
        loader={<Loader key={0} />}
        useWindow
        threshold={350}
      >
        <ul className='flex flex-row flex-wrap'>
          {items.map(item => <ReturnItem key={item.project_id} item={item} />)}
        </ul>
      </InfiniteScroll>
    </div>
  )
}

export { HistoryList }
