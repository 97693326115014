import { useNavigate } from 'react-router-dom'
import { logoutRequest } from './operations'

const LogoutButton = () => {
  const navigate = useNavigate()

  const handleLogout = async () => {
    await logoutRequest()
    navigate('/auth')
  }

  return (
    <div className='flex flex-col items-center'>
      <button className='mt-4 text-xs' onClick={handleLogout}>
        Logout
      </button>
    </div>
  )
}

export { LogoutButton }
