/* global localStorage */

import 'dotenv/config'
import React, { useReducer } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { Home } from './pages/Home'
import { History } from './pages/History'
import { Project } from './pages/Project'
import { Lab } from './pages/LabPagination'

import { FETCH_HISTORY, historyReducer } from './history'
import { GENERATION_ERROR, GENERATION_SUCCESS, generationReducer } from './generation'
import { LOADING_FINISH, LOADING_START, loaderReducer } from './loader'
import { IMAGE_UPLOAD_ERROR, IMAGE_UPLOAD_SUCCESS, imageReducer } from './imageUploader'
import { CREATE_PROJECT_SUCCESS, FETCH_PROJECT_SUCCESS, DELETE_PROJECT_SUCCESS, CLONE_PROJECT_SUCCESS, projectReducer } from './project'
import { TOGGLE_TO_IMAGE, TOGGLE_TO_VIDEO, toggleReducer } from './toggleMedia'
import { SET_VOICE_SUCCESS, FETCH_VOICES_SUCCESS, voiceReducer } from './voiceSelector'
import { CLEAR_ERRORS, errorReducer } from './errors'
import { PrivateRoute } from './routes/PrivatRoute'
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary'
import { ErrorFallback } from './ErrorBoundary/ErrorFallback'

export const AppContext = React.createContext(null)

const initialState = {
  history: [], //   id, image_hash, project_id, speaking_rate, text, video_path, voice
  projectsCount: 0,
  generationForm: { speakingRate: '', voice: '', text: '', details: [] },
  loading: false,
  showVideo: false,
  voices: [],
  currentProject: {
    projectId: '',
    videoId: '',
    imageHash: '',
    imageExtension: ''
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_HISTORY:
      return historyReducer(state, action)
    case GENERATION_SUCCESS:
      return generationReducer(state, action)
    case GENERATION_ERROR:
      return generationReducer(state, action)
    case LOADING_START:
      return loaderReducer(state, action)
    case LOADING_FINISH:
      return loaderReducer(state, action)
    case IMAGE_UPLOAD_SUCCESS:
      return imageReducer(state, action)
    case IMAGE_UPLOAD_ERROR:
      return imageReducer(state, action)
    case CREATE_PROJECT_SUCCESS:
      return projectReducer(state, action)
    case FETCH_PROJECT_SUCCESS:
      return projectReducer(state, action)
    case DELETE_PROJECT_SUCCESS:
      return projectReducer(state, action)
    case CLONE_PROJECT_SUCCESS:
      return projectReducer(state, action)
    case TOGGLE_TO_IMAGE:
      return toggleReducer(state, action)
    case TOGGLE_TO_VIDEO:
      return toggleReducer(state, action)
    case SET_VOICE_SUCCESS:
      return voiceReducer(state, action)
    case FETCH_VOICES_SUCCESS:
      return voiceReducer(state, action)
    case CLEAR_ERRORS:
      return errorReducer(state, action)

    default:
      return state
  }
}

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => localStorage.setItem('isLoggedIn', 'false')}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route
              path='/project/:projectId'
              element={
                <PrivateRoute>
                  <Project />
                </PrivateRoute>
              }
            />
            <Route
              path='/history'
              element={
                <PrivateRoute>
                  <History />
                </PrivateRoute>
              }
            />
            <Route
              path='/lab'
              element={
                <PrivateRoute>
                  <Lab />
                </PrivateRoute>
              }
            />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export { App }
