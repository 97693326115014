import React, { useRef, useEffect } from 'react'

const Video = ({ url }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    videoRef.current?.load()
  }, [url])

  console.log(url)

  return (
    <video ref={videoRef} className='mt-4 mb-4 w-full h-full' controls playsInline loop>
      <source src={url} type='video/mp4' />
    </video>
  )
}

export { Video }
