import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLongPress } from '@uidotdev/usehooks'

import { AppContext } from '../App'
import { deleteProject, cloneProject } from '../project'
import { imageFallbackURL } from '../utils/imageFallback'
import { useAsyncError } from '../ErrorBoundary/useAsyncError'
import { Config } from '../utils/config'

const HistoryListItem = ({ item, onDelete }) => {
  const throwAsyncError = useAsyncError()
  const [fallbackURL, setFallbackURL] = useState('')
  useEffect(() => {
    async function getImageFallbackURL() {
      const imageUrl = await imageFallbackURL(item.project_id, 250)
      setFallbackURL(imageUrl)
    }

    getImageFallbackURL()
  }, [item.projectId])

  const imageUrl = item.image_extension
    ? `${Config.BASE_URL}/images/${item.project_id}.${item.image_extension}`
    : fallbackURL

  const [showOverlay, setShowOverlay] = useState(false)
  const { dispatch } = useContext(AppContext)

  const navigate = useNavigate()

  const handleOpen = () => {
    if (!showOverlay) navigate(`/project/${item.project_id}`)
  }

  const attrs = useLongPress(
    () => {
      setShowOverlay(true)
    },
    {
      threshold: 500
    }
  )

  const handleDelete = async () => {
    setShowOverlay(false)
    deleteProject(dispatch, { projectId: item.project_id }, throwAsyncError)
  }

  const handleClone = async () => {
    setShowOverlay(false)
    cloneProject(dispatch, { projectId: item.project_id }, throwAsyncError)
  }

  const handleClose = (event) => {
    setShowOverlay(false)
  }

  const overlayClass = showOverlay
    ? 'absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center' // Overlay visible
    : 'absolute top-0 left-0 w-full h-full bg-black bg-opacity-0 flex justify-center items-center' // Overlay hidden

  return (
    <li
      className='w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 p-2 sm:p-2 md:p-3 lg:p-3'
      key={item.id}
    >
      <div {...attrs} onClick={handleOpen} className='relative h-full w-full flex items-center'>
        <div className='items-center'>
          <img
            className='h-auto w-full rounded'
            src={imageUrl}
            alt={`Image ${item.id}`}
          />
        </div>
        <div className={overlayClass}>
          {showOverlay && (
            <div className='flex flex-col gap-6 w-2/5'>
              <button
                className='absolute right-3 top-3 text-lg text-white'
                onClick={handleClose}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path
                    fill='white'
                    d='M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-3.4 14L12 13.4L8.4 17L7 15.6l3.6-3.6L7 8.4L8.4 7l3.6 3.6L15.6 7L17 8.4L13.4 12l3.6 3.6l-1.4 1.4Z'
                  />
                </svg>
              </button>
              <button className='btn btn-warning w-full bg-opacity-80' onClick={handleClone}>
                CLONE
              </button>
              <button className='btn btn-error w-full bg-opacity-80' onClick={handleDelete}>
                DELETE
              </button>
            </div>
          )}
          <div style={{ backgroundImage: `url('${imageUrl}')`, backdropFilter: 'blur(64px)', backgroundSize: 'cover' }} className='absolute z-[-2] w-full h-full' />
          <div style={{ backdropFilter: 'blur(4px)', backgroundSize: 'cover' }} className='z-[-1] absolute w-full h-full blur-md' />
        </div>
      </div>
    </li>
  )
}

export { HistoryListItem }
