import { Logo } from '../logo'

const Header = ({ title, withLogo = false }) => {
  return (
    <div className='flex flex-row w-full justify-center'>
      {withLogo && (<a href='/' className='flex'><Logo size='xs' /></a>)}
      <h1 className='text-5xl mb-5 mt-5 ml-3'>{title}</h1>
    </div>
  )
}

export { Header }
