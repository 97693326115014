import { Config } from './config'

const getCsrfToken = async () => {
  const response = await fetch(`${Config.BASE_URL}/csrf-token`, {
    method: 'GET',
    credentials: 'include'
  })

  if (response.ok) {
    const data = await response.json()
    return data.csrfToken
  } else {
    console.log('Failed to fetch CSRF token')
  }
}

export { getCsrfToken }
