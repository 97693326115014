import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { AppContext } from '../App'
import { GenerationForm } from '../generation'
import { fetchProject } from '../project'
import { startLoading, finishLoading } from '../loader'
import { ToggleMedia } from '../toggleMedia'
import { Video } from '../video'
import { Header } from '../header'
import { useAsyncError } from '../ErrorBoundary/useAsyncError'
import { Config } from '../utils/config'

const Project = () => {
  const { state, dispatch } = useContext(AppContext)
  const throwAsyncError = useAsyncError()
  const location = useLocation()

  const imageHash = state.currentProject.imageHash
  const videoId = state.currentProject.videoId
  // TODO: Get projectId from the state instead of the URL
  const projectId = location.pathname.split('/')[2]
  const imageUrl = `${Config.BASE_URL}/images/${projectId}.${state.currentProject.imageExtension}`
  const videoUrl = `${Config.BASE_URL}/videos/${projectId}`

  useEffect(() => {
    async function startFetching() {
      try {
        dispatch(startLoading())
        await fetchProject(dispatch, { projectId }, throwAsyncError)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(finishLoading())
      }
    }

    if (projectId !== state.currentProject.projectId) {
      startFetching()
    }
  }, [dispatch])

  const image = () => {
    return (
      <img
        className='mt-4 mb-4 w-full h-full'
        src={`${imageUrl}?hash=${imageHash}`}
      />
    )
  }

  const button = () => {
    return (
      <a
        href={`${videoUrl}?download=✓`}
        download={`${projectId}.mp4`}
        className='btn w-full mb-4'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path
            fill='currentColor'
            d='M16.707 7.404c-.189-.188-.448-.283-.707-.283s-.518.095-.707.283L13 9.697V3a1 1 0 0 0-2 0v6.697L8.707 7.404a.997.997 0 0 0-1.414 0a1 1 0 0 0 0 1.414L12 13.5l4.709-4.684a1 1 0 0 0-.002-1.412zM20.987 16a.98.98 0 0 0-.039-.316l-2-6A.998.998 0 0 0 18 9h-.219c-.094.188-.21.368-.367.525L15.932 11h1.348l1.667 5H5.054l1.667-5h1.348L6.586 9.525A1.96 1.96 0 0 1 6.219 9H6a.998.998 0 0 0-.948.684l-2 6a.98.98 0 0 0-.039.316C3 16 3 21 3 21a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1s0-5-.013-5z'
          />
        </svg>
        Download
      </a>
    )
  }

  return (
    <div className=' flex flex-col items-center mt-4'>
      <Header title='Project' withLogo />

      <div className='w-full max-w-md px-8 '>
        {imageHash && state.currentProject.videoId && <ToggleMedia />}
        {state.showVideo && state.currentProject.videoId && (
          <Video url={`${videoUrl}`} />
        )}
        {imageHash && !state.showVideo && image()}
        {state.showVideo && state.currentProject.videoId && button()}
        <GenerationForm />
      </div>

    </div>
  )
}

export { Project }
