import { IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_ERROR } from '.'

const imageUploadSuccessAction = (payload) => {
  return {
    type: IMAGE_UPLOAD_SUCCESS,
    payload
  }
}
const imageUploadErrorAction = (payload) => {
  return {
    type: IMAGE_UPLOAD_ERROR,
    payload
  }
}

export { imageUploadSuccessAction, imageUploadErrorAction }
