import React, { useContext, forwardRef, useEffect } from 'react'
import { AppContext } from '../App'
import { setVoiceSuccessAction, fetchVoices } from '.'
import { useAsyncError } from '../ErrorBoundary/useAsyncError'

const VoiceSelector = forwardRef((field, ref) => {
  const { state, dispatch } = useContext(AppContext)
  const throwAsyncError = useAsyncError()

  useEffect(() => {
    async function startFetching () {
      try {
        await fetchVoices(dispatch, throwAsyncError)
      } catch (error) {
        console.error(error)
      }
    }

    startFetching()
  }, [dispatch])

  const changeVoice = (e) => {
    field.onChange(e.target.value)
    dispatch(setVoiceSuccessAction(e.target.value))
  }

  const voices = []

  Object.entries(state.voices).map(([k, v], i) => {
    return [k, ...Object.values(v)]
  }).sort((a, b) => {
    // NOTE: [ 0: voiceName, 1: gender, 2: popularity ]
    return b[2] - a[2]
  }).forEach((v) => {
    const voiceSlice = v[0].split('-')
    voices.push(<option key={v[0]} value={v[0]}>{`${voiceSlice[0]}-${voiceSlice[1]} :: ${voiceSlice[2]}-${voiceSlice[3]}`} {v[1] === 'm' ? '(male)' : '(female)'}</option>)
  })

  return (
    <select
      ref={ref}
      value={state.generationForm.voice || ''}
      onChange={changeVoice}
      className='select select-bordered w-full max-w-sm shadow-md my-2'
    >
      <option />
      {voices}
    </select>
  )
})

export { VoiceSelector }
