import { createNewProject } from '.'
import { Navigate } from 'react-router-dom'
import { useAsyncError } from '../ErrorBoundary/useAsyncError'

import { useContext } from 'react'
import { AppContext } from '../App'

const NewProjectButton = () => {
  const { state, dispatch } = useContext(AppContext)
  const throwAsyncError = useAsyncError()

  return (
    <>
      {(!state.currentProject.projectId)
        ? (
          <button
            className='btn btn-success mt-10'
            onClick={() => createNewProject(dispatch, throwAsyncError)}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='currentColor' d='m9 4l2.5 5.5L17 12l-5.5 2.5L9 20l-2.5-5.5L1 12l5.5-2.5L9 4m0 4.83L8 11l-2.17 1L8 13l1 2.17L10 13l2.17-1L10 11L9 8.83M19 9l-1.26-2.74L15 5l2.74-1.25L19 1l1.25 2.75L23 5l-2.75 1.26L19 9m0 14l-1.26-2.74L15 19l2.74-1.25L19 15l1.25 2.75L23 19l-2.75 1.26L19 23Z' /></svg>
            New project
          </button>
          )
        : (
          <Navigate to={`/project/${state.currentProject.projectId}`} />
          )}
    </>
  )
}

export { NewProjectButton }
