const image = require('../../assets/img/witts-logo.svg')

const Logo = ({ size, className }) => {
  const sizes = {
    xs: 'w-12',
    sm: 'w-16',
    md: 'w-20',
    lg: 'w-48',
    xl: 'w-96'
  }

  return (
    <img
      src={image}
      alt='WITTS logo'
      className={`${className}  mx-auto ${sizes[size]}`}
    />
  )
}

export { Logo }
