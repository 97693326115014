async function strToRange (str, rangeSize) {
  const encoder = new TextEncoder()
  const data = encoder.encode(str)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data)
  const view = new DataView(hashBuffer, 0)
  const hashNumber = view.getBigUint64(0, true)

  return Number(hashNumber % BigInt(rangeSize)) + 1
}

// TODO: Possibly extract the image URL and size to environment variables
async function imageFallbackURL (uuid) {
  const rangeSize = 250
  const imageNumber = await strToRange(uuid, rangeSize)
  return `https://picsum.photos/seed/${imageNumber}/1080/1350?grayscale`
}

export { imageFallbackURL }
