import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { authRequest } from './operations'

const AuthorizationForm = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    const username = e.target.elements.username.value
    const password = e.target.elements.password.value

    if (!username || !password) {
      setError('Username and password are required')
      return
    }

    setError('')

    try {
      await authRequest(username, password)
      navigate('/home')
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  }

  return (
    <div className='flex flex-col w-full md:w-4/6 sm:w-3/6 lg:w-2/6 xl:w-1/5'>
      <form
        className='flex flex-col items-center min-w-fit'
        onSubmit={handleSubmit}
      >
        <input
          className='input input-bordered min-w-full shadow py-2 px-3 my-2 decoration-1 placeholder-gray-600'
          placeholder='Username'
          name='username'
          autoComplete='username'
        />
        <input
          className='input input-bordered min-w-full shadow py-2 px-3 my-2 decoration-1 placeholder-gray-600'
          placeholder='Password'
          type='password'
          name='password'
          autoComplete='current-password'
        />

        {error && <p className='text-red-500 mt-3'>{error}</p>}

        <button className='btn btn-success mt-3' type='submit'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path
              fill='currentColor'
              d='M12 21v-2h7V5h-7V3h7q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm-2-4l-1.375-1.45l2.55-2.55H3v-2h8.175l-2.55-2.55L10 7l5 5z'
            />
          </svg>
          Login
        </button>
      </form>
    </div>
  )
}

export { AuthorizationForm }
