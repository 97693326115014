import { SET_VOICE_SUCCESS, FETCH_VOICES_SUCCESS } from '.'

const voiceReducer = (state, action) => {
  switch (action.type) {
    case SET_VOICE_SUCCESS:
      return {
        ...state,
        generationForm: {
          ...state.generationForm,
          voice: action.payload
        }
      }
    case FETCH_VOICES_SUCCESS:
      return {
        ...state,
        voices: action.payload.allVoices
      }
    default:
      return state
  }
}

export { voiceReducer }
