import React, { useContext, forwardRef } from 'react'
import { AppContext } from '../App'
import { handleImageUpload } from '.'

const ImageInput = forwardRef((field, ref) => {
  const { state, dispatch } = useContext(AppContext)

  const changeImage = (e) => {
    field.onChange(e.target.files[0])
    handleImageUpload(dispatch, e.target.files[0], state.currentProject.projectId)
  }

  return (
    <>
      <input
        ref={ref}
        type='file'
        className='file-input file-input-bordered w-full max-w-sm shadow my-2'
        accept='image/*'
        onChange={changeImage}
      />
    </>
  )
})

export { ImageInput }
