import { IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_ERROR } from '.'

const imageReducer = (state, action) => {
  switch (action.type) {
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          projectId: state.currentProject.projectId,
          imageHash: action.payload.file_hash,
          imageExtension: action.payload.image_extension
        }

      }
    case IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          details: [
            { message: action.payload.error, path: ['image_name'] }
          ]
        }

      }

    default:
      return state
  }
}

export { imageReducer }
