import { GENERATION_SUCCESS, GENERATION_ERROR } from '.'

const generationReducer = (state, action) => {
  switch (action.type) {
    case GENERATION_SUCCESS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          videoId: action.payload.video_id
        }
      }
    case GENERATION_ERROR:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          details: [...action.payload.details]

        }
      }

    default:
      return state
  }
}

export { generationReducer }
