import { CLEAR_ERRORS } from '.'

const errorReducer = (state, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          details: []
        }
      }

    default:
      return state
  }
}
export { errorReducer }
