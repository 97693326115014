import { SET_VOICE_SUCCESS, FETCH_VOICES_SUCCESS } from '.'

const setVoiceSuccessAction = (payload) => {
  return {
    type: SET_VOICE_SUCCESS,
    payload
  }
}

const fetchVoicesSuccessAction = (payload) => {
  return {
    type: FETCH_VOICES_SUCCESS,
    payload
  }
}

export { setVoiceSuccessAction, fetchVoicesSuccessAction }
