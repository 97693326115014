/* global localStorage */
import { Config } from '../utils/config'

const logoutRequest = async () => {
  try {
    const response = await fetch(`${Config.BASE_URL}/logout`, {
      method: 'GET',
      credentials: 'include'
    })
    if (response.ok) {
      await response.json()
      localStorage.setItem('isLoggedIn', 'false')
    } else {
      const errorData = await response.json()
      throw new Error(errorData.error)
    }
  } catch (error) {
    console.error(error)
  }
}

export { logoutRequest }
