import { HistoryList } from '../history'
import { Header } from '../header'

const History = () => {
  return (
    <div className='container mx-0 min-w-full min-h-full flex flex-col items-center mt-4'>
      <Header title='History' withLogo />
      <HistoryList />
    </div>
  )
}

export { History }
