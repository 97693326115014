import { GENERATION_SUCCESS, GENERATION_ERROR } from '.'

const generationSuccessAction = (payload) => {
  return {
    type: GENERATION_SUCCESS,
    payload
  }
}
const generationErrorAction = (payload) => {
  return {
    type: GENERATION_ERROR,
    payload
  }
}

export { generationSuccessAction, generationErrorAction }
