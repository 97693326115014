import { FETCH_HISTORY } from '.'

const historyAction = (payload) => {
  return {
    type: FETCH_HISTORY,
    payload
  }
}

export { historyAction }
