import InfiniteScroll from 'react-infinite-scroller'
import { useContext, useEffect } from 'react'
import { AppContext } from '../App'
import { startLoading, finishLoading, Loader } from '../loader'
import { fetchHistory } from '../history'

const Lab = () => {
  const { state, dispatch } = useContext(AppContext)
  const limit = 4
  const initOffset = 0

  useEffect(() => {
    async function startFetching () {
      try {
        dispatch(startLoading())
        await fetchHistory(dispatch, limit, initOffset)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(finishLoading())
      }
    }
    startFetching()
  }, [])

  const loadFunc = async (pageNumber) => {
    const offset = pageNumber * limit

    await fetchHistory(dispatch, limit, offset)
  }

  const items = state.history

  let scrollParentRef = null
  return (
    <>
      <Loader />
      <h1 className='text-5xl mb-2 m-5'>Lab</h1>
      <div className='container min-w-full min-h-full flex flex-col items-center justify-center'>
        <div className='flex border h-24 overflow-y-scroll sm w-max' ref={(ref) => (scrollParentRef = ref)}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={loadFunc}
            hasMore={items.length < state.projectsCount}
            loader={<Loader />}
            useWindow={false}
            threshold={1}
            getScrollParent={() => scrollParentRef}
          >
            <ul className='flex flex-col'>
              {
                items.map((item, index) => <li className='flex' key={item.project_id}>{item.project_id}</li>)
              }
            </ul>
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export { Lab }
