import { startLoading, finishLoading } from '../loader'
import { generationErrorAction, generationSuccessAction } from '.'
import { toggleVideoActionSuccess } from '../toggleMedia'
import { getCsrfToken } from '../utils/csrfUtils'
import { Config } from '../utils/config'

const saveGenerationForm = async (dispatch, payload, throwAsyncError) => {
  dispatch(startLoading())

  try {
    const csrfToken = await getCsrfToken()

    const response = await fetch(`${Config.BASE_URL}/generate`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfToken
      },
      body: JSON.stringify({ ...payload })
    })

    const result = await response.json()

    if (response.status === 400) {
      dispatch(generationErrorAction(result))
    } else if (!response.ok) {
      const error = new Error(`HTTP error! Status: ${response.status}`)
      error.status = response.status
      throw error
    } else {
      dispatch(generationSuccessAction(result))
      dispatch(toggleVideoActionSuccess())
    }
  } catch (error) {
    error.status ? throwAsyncError(error) : console.error(error)
  } finally {
    dispatch(finishLoading())
  }
}

export { saveGenerationForm }
