import { TOGGLE_TO_IMAGE, TOGGLE_TO_VIDEO } from '.'

const toggleImageActionSuccess = () => {
  return {
    type: TOGGLE_TO_IMAGE
  }
}
const toggleVideoActionSuccess = () => {
  return {
    type: TOGGLE_TO_VIDEO
  }
}

export { toggleImageActionSuccess, toggleVideoActionSuccess }
