import { FETCH_HISTORY } from './types'

const historyReducer = (state, action) => {
  switch (action.type) {
    case FETCH_HISTORY:
      return {
        ...state,
        history: [...state.history, ...action.payload.projects],
        projectsCount: action.payload.projects_count
      }
    default:
      return state
  }
}

export { historyReducer }
