import React from 'react'
import { useNavigate } from 'react-router-dom'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate()
  let errorMessage = 'Something went wrong.'

  if (error) {
    switch (error.status) {
      case 401:
        errorMessage = 'Not authorized. Please sign in again.'
        break
      case 500:
        errorMessage = 'Server error.'
        break
      case 429:
        errorMessage = 'Too many requests, please try again later.'
        break
      default:
        errorMessage = 'Something went wrong.'
        break
    }
  }

  const handleRetry = () => {
    resetErrorBoundary()
    navigate('/')
  }

  return (
    <div className='container py-10 px-10 mx-0 min-w-full min-h-full flex flex-col items-center justify-center'>
      <p className='text-center text-red-500'>{errorMessage}</p>
      <button className='btn btn-success mt-3' onClick={handleRetry}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path
            fill='currentColor'
            d='M6 19h3.692v-5.884h4.616V19H18v-9l-6-4.538L6 10zm-1 1V9.5l7-5.288L19 9.5V20h-5.692v-5.884h-2.616V20zm7-7.77'
          />
        </svg>
        Home
      </button>
    </div>
  )
}

export { ErrorFallback }
