/* global localStorage */
import { NewProjectButton } from '../project'
import { Logo } from '../logo'
import { LogoutButton } from '../logout/LogoutButton'
import { AuthorizationForm } from '../auth/AuthorizationForm'

const { useMediaQuery } = require('@uidotdev/usehooks')

const Home = () => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

  const isExtraSmallDevice = useMediaQuery(
    'only screen and (max-width : 640px)'
  )
  const isExtraSmallLandscapeDevice = useMediaQuery(
    'only screen and (orientation: landscape) and (max-width : 640px)'
  )
  const isSmallDevice = useMediaQuery('only screen and (min-width: 640px)')

  let size = ''
  const debugClass = ''
  if (!size) {
    switch (true) {
      case isExtraSmallLandscapeDevice:
        size = 'sm'
        break
      case isExtraSmallDevice:
      case isSmallDevice:
        size = 'lg'
        break
    }
  }

  return (
    <div className='container py-10 px-10 mx-0 min-w-full min-h-full flex flex-col items-center justify-center overflow-hidden'>
      <div className='flex flex-col items-center min-w-full'>
        <div className='grid align-bottom items-center content-center gap-0 landscape:xs:items-center landscape:xs:grid-cols-2 landscape:md:grid-cols-1'>
          <Logo className={'grid' + debugClass} size={size} />
          <h1 className='text-5xl xs:mt-0 md:ml-0 md:mb-2 md:mt-5 text-center font-logo front-normal not-italic landscape:xs:max-md:ml-[-25px]'>
            WITTS
          </h1>
        </div>
        {isLoggedIn
          ? (
            <>
              <NewProjectButton />
              <a className='slate-500 mt-5' href='/history'>
                History
              </a>
              <LogoutButton />
            </>
            )
          : (
            <AuthorizationForm />
            )}
      </div>
    </div>
  )
}

export { Home }
