import {
  CREATE_PROJECT_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  CLONE_PROJECT_SUCCESS
} from '.'

const projectReducer = (state, action) => {
  switch (action.type) {
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        currentProject: { projectId: action.payload.project_id },
        history: []
      }

    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        history: [],
        generationForm: {
          speakingRate: action.payload.project.speaking_rate,
          voice: action.payload.project.voice,
          text: action.payload.project.text
        },

        currentProject: {
          projectId: action.payload.project.project_id,
          imageHash: action.payload.project.image_hash,
          videoId: action.payload.project.video_id,
          imageExtension: action.payload.project.image_extension
        }
      }
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        history: state.history.filter((item) => item.project_id !== action.payload.project_id)
      }

    case CLONE_PROJECT_SUCCESS:
      return {
        ...state,
        history: [
          {
            ...action.payload
          },
          ...state.history
        ]
      }

    default:
      console.error('Unknown action', action.type, action.payload)
      return state
  }
}

export { projectReducer }
