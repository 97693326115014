import { imageUploadErrorAction, imageUploadSuccessAction } from '.'
import { clearErrorsSuccessAction } from '../errors'
import { startLoading, finishLoading } from '../loader'
import { toggleImageActionSuccess } from '../toggleMedia'
import { getCsrfToken } from '../utils/csrfUtils'
import { Config } from '../utils/config'

const handleImageUpload = async (dispatch, fileInput, currentProject) => {
  if (!fileInput) return

  const MAX_IMAGE_SIZE = Number(process.env.MAX_IMAGE_SIZE) || 5
  const fileSizeInMB = fileInput.size / (1024 ** 2)

  if (fileSizeInMB > MAX_IMAGE_SIZE) {
    dispatch(imageUploadErrorAction({ error: 'File size exceeds the 5MB limit.' }))
    return
  }

  const formData = new FormData()

  dispatch(startLoading())

  formData.append('image', fileInput)
  formData.append('projectId', currentProject)

  try {
    const csrfToken = await getCsrfToken()

    const response = await fetch(`${Config.BASE_URL}/upload`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'x-csrf-token': csrfToken
      },
      body: formData
    })

    const result = await response.json()

    if (!response.ok) {
      dispatch(imageUploadErrorAction(result))
      return
    }
    dispatch(clearErrorsSuccessAction())
    dispatch(imageUploadSuccessAction(result))
  } catch (error) {
    console.error(error)
  } finally {
    dispatch(finishLoading())
    dispatch(toggleImageActionSuccess())
  }
}

export { handleImageUpload }
