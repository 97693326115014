/* global localStorage */

import React from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

  return isLoggedIn ? children : <Navigate to='/auth' />
}

export { PrivateRoute }
