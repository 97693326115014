import { TOGGLE_TO_IMAGE, TOGGLE_TO_VIDEO } from '.'

const toggleReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_TO_IMAGE:
      return {
        ...state,
        showVideo: false
      }
    case TOGGLE_TO_VIDEO:
      return {
        ...state,
        showVideo: true
      }

    default:
      return state
  }
}

export { toggleReducer }
