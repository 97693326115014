import React, { useContext } from 'react'
import { AppContext } from '../App'

const Loader = (key) => {
  const { state } = useContext(AppContext)

  if (state.loading) {
    return <div key={key} className='loader m-5' />
  }

  return null
}

export { Loader }
