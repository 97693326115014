import React from 'react'

class ErrorBoundary extends React.Component {
  state = { error: null }

  static getDerivedStateFromError (error) {
    return { error }
  }

  componentDidCatch (error) {
    console.error(error)
  }

  resetErrorBoundary = () => {
    this.setState({ error: null })
    this.props.onReset()
  }

  render () {
    const { FallbackComponent, children } = this.props
    const { error } = this.state
    if (error) {
      return (
        <FallbackComponent
          error={error}
          resetErrorBoundary={this.resetErrorBoundary}
        />
      )
    }

    return children
  }
}

export { ErrorBoundary }
