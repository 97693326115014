/* global localStorage */

import { getCsrfToken } from '../utils/csrfUtils'
import { Config } from '../utils/config'

const authRequest = async (username, password) => {
  try {
    const csrfToken = await getCsrfToken()

    const response = await fetch(`${Config.BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfToken
      },
      body: JSON.stringify({ username, password }),
      credentials: 'include'
    })

    if (response.ok) {
      const data = await response.json()
      localStorage.setItem('isLoggedIn', 'true')
      return data
    } else {
      const errorData = await response.json()
      if (errorData.details && errorData.details.length > 0) {
        throw new Error(errorData.details[0].message)
      } else {
        throw new Error(errorData.error || 'Authentication failed')
      }
    }
  } catch (error) {
    if (error.message === 'Failed to fetch') {
      throw new Error('Server offline. Please try again later.')
    }

    console.error('Login error:', error)
    localStorage.setItem('isLoggedIn', 'false')
    throw error
  }
}

export { authRequest }
