import { useForm, Controller } from 'react-hook-form'
import { useContext, useEffect, useRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'

import { useAsyncError } from '../ErrorBoundary/useAsyncError'
import { AppContext } from '../App'
import { saveGenerationForm } from '.'
import { Loader } from '../loader'
import { ImageInput } from '../imageUploader'
import { VoiceSelector } from '../voiceSelector'
import { clearErrorsSuccessAction } from '../errors'

const extractErrors = (errors, el) => {
  return errors.filter((e) => e.path.includes(el)).map((e) => e.message)
}

const GenerationForm = () => {
  const { state, dispatch } = useContext(AppContext)
  const throwAsyncError = useAsyncError()
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()

  const adjustHeight = (e) => {
    e.target.style.height = 'auto'
    e.target.style.height = `${e.target.scrollHeight + 10}px`
  }

  // NOTE: https://stackoverflow.com/questions/71495923/how-to-use-the-ref-with-the-react-hook-form-react-library
  const { ref: reactHookFormTextAreaRef, ...reactHookFormPros } = register('text', {
    required: 'This fild is required',
    minLength: {
      value: 4,
      message: 'Min length is 4'
    },
    maxLength: {
      value: 750,
      message: 'Max length is 750'
    }
  })

  const textareaRef = useRef(null)

  useEffect(() => {
    reset({
      speaking_rate: state.generationForm.speakingRate,
      text: state.generationForm.text
    })

    textareaRef.current.value = state.generationForm.text

    adjustHeight({ target: textareaRef.current })
  }, [state.generationForm.speakingRate, state.generationForm.text])

  const onSubmit = (data) => {
    data.project_id = state.currentProject.projectId
    data.image_name = `${state.currentProject.projectId}.${state.currentProject.imageExtension}`
    data.voice = state.generationForm.voice

    saveGenerationForm(dispatch, data, throwAsyncError)
    dispatch(clearErrorsSuccessAction())
  }

  return (
    <form
      className='rounded pt-6 pb-8 mb-4 w-full'
      onSubmit={handleSubmit(onSubmit)}
    >
      <label
        className='block text-gray-700 text-sm font-bold mt-2'
        htmlFor='image_name'
      >
        Image
      </label>

      <Controller
        control={control}
        rules={state.currentProject.imageHash
          ? {}
          : {
              required: 'An image must be selected'
            }}
        name='image_name'
        render={({ field }) => <ImageInput {...field} />}
      />

      <span className='text-red-700 font-bold w-full flex my-2'>
        <ErrorMessage errors={errors} name='image_name' />
      </span>

      {state.currentProject.details && (
        <span className='text-red-700 font-bold w-full flex my-2'>
          {extractErrors(state.currentProject.details, 'image_name')}
        </span>
      )}

      <label
        className='block text-gray-700 text-sm font-bold mt-2'
        htmlFor='voice'
      >
        TTS Voice
      </label>

      <Controller
        control={control}
        name='voice'
        render={({ field }) => <VoiceSelector {...field} />}
      />

      <span className='text-red-700 font-bold w-full flex my-2'>
        <ErrorMessage errors={errors} name='voice' />
      </span>
      {state.currentProject.details && (
        <span className='text-red-700 font-bold w-full flex my-2'>
          {extractErrors(state.currentProject.details, 'voice')}
        </span>
      )}

      <label
        className='block text-gray-700 text-sm font-bold mt-2'
        htmlFor='speaking_rate'
      >
        Speaking Rate
      </label>

      <input
        className='input input-bordered w-full shadow py-2 px-3 my-2 decoration-1 placeholder-gray-600'
        placeholder='0.25 - 4.0'
        defaultValue={state.generationForm.speakingRate}
        {...register('speaking_rate', {
          required: 'This field is required',
          min: {
            value: 0.25,
            message: 'This input is number only, from 0.25 to 4.0'
          },
          max: {
            value: 4.0,
            message: 'This input is number only, from 0.25 to 4.0'
          }
        })}
        id='speaking_rate'
      />
      <span className='text-red-700 font-bold w-full flex my-2'>
        <ErrorMessage errors={errors} name='speaking_rate' />
      </span>

      {state.currentProject.details && (
        <span className='text-red-700 font-bold w-full flex my-2'>
          {extractErrors(state.currentProject.details, 'speaking_rate')}
        </span>
      )}

      <label
        className='block text-gray-700 text-sm font-bold mt-2'
        htmlFor='ssml_text'
      >
        Text
      </label>
      <textarea
        className='textarea textarea-bordered textarea-lg w-full h-auto shadow-md my-2 leading-tight max-h-[400px]'
        defaultValue={state.generationForm.text}
        ref={(e) => {
          reactHookFormTextAreaRef(e)
          textareaRef.current = e
        }}
        id='ssml_text'
        name='text'
        onInput={(e) => adjustHeight(e)}
        {...reactHookFormPros}
      />
      <span className='text-red-700 font-bold w-full flex my-2'>
        <ErrorMessage errors={errors} name='text' />
      </span>
      {state.currentProject.details && (
        <span className='text-red-700 font-bold w-full flex my-2'>
          {extractErrors(state.currentProject.details, 'text')}
        </span>
      )}
      <div className='flex justify-end'>
        {state.loading
          ? (
            <Loader />
            )
          : (
            <button
              className='items-center py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-500 hover:bg-blue-700 text-white'
              type='submit'
            >
              {' '}
              Generate
            </button>
            )}
      </div>
    </form>
  )
}

export { GenerationForm }
