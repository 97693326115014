import { historyAction } from '.'
import { Config } from '../utils/config'

const fetchHistory = async (dispatch, limit, offset, throwAsyncError) => {
  try {
    const response = await fetch(
      `${Config.BASE_URL}/history?limit=${limit}&offset=${offset}`,
      {
        method: 'GET',
        credentials: 'include'
      }
    )

    if (!response.ok) {
      const error = new Error(`HTTP error! Status: ${response.status}`)
      error.status = response.status
      throw error
    }

    const result = await response.json()
    dispatch(historyAction(result))
  } catch (error) {
    console.error(error)
    throwAsyncError(error)
  }
}

export { fetchHistory }
