import { CLEAR_ERRORS } from '.'

const clearErrorsSuccessAction = (payload) => {
  return {
    type: CLEAR_ERRORS,
    payload
  }
}

export { clearErrorsSuccessAction }
